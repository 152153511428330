.report-report-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.report-report-header-download-button {
    margin-left: 10px;
}

.report-report-month {
    display: flex;
    align-items: center;
    margin: 15px 0px;
}

.report-report-month-search {
    width: 250px;
    margin-right: 5px;
}

.report-report-month-range {
    width: 250px;
    margin-right: 5px;
}

.report-report-month-select {
    width: 250px;
}

@media only screen and (max-width: 1200px) {
    .report-report-header {
        display: block;
    }

    .report-report-header-download-button {
        margin-left: auto;
        margin-bottom: 5px;
        width: 300px;
    }

    .report-report-month {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 5px;
        justify-content: center;
        width: 300px;
    }

    .report-report-month-search {
        width: 300px;
        margin-right: 5px;
    }

    .report-report-month-range {
        margin-top: 5px;
        width: 300px;
    }

    .report-report-month-select {
        margin-top: 5px;
        width: 300px;
    }
}

@media only screen and (max-width: 400px) {
    .report-report-header {
        display: block;
    }

    .report-report-header-download-button {
        width: 200px;
    }

    .report-report-month {
        width: 200px;
    }

    .report-report-month-search {
        width: 200px;
    }

    .report-report-month-range {
        width: 200px;
    }

    .report-report-month-select {
        width: 200px;
    }
}


.report-report-month-picker {
    margin-left: 20px;
    width: 200px !important;
}

.__no-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.__table-text-centered {
    text-align: center !important;
}

.__table-text-left {
    text-align: left !important;
}

.__table-text-right {
    text-align: right !important;
}

.report-ccnt-list-main .ant-list-header,
.report-ccnt-list-main .ant-list-item {
    padding-left: 14px !important;
    padding-right: 14px !important;
}

.report-ccnt-list-users {
    cursor: pointer;
}


.report-ccnt-list-users .ant-list-item-meta-description {
    font-size: 90%;
}

.report-ccnt-list-users:last-child {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.__report-user-list .ant-table th,
.__poi-list .ant-table th,
.__report-hours-table .ant-table th,
.__sunkhronos-live-table .ant-table th,
.__team-management-user-list .ant-table th,
.__time-clock-table .ant-table th,
.__basic-table .ant-table th {
    width: fit-content;
}

.__team-management-user-list .ant-table .ant-avatar-lg {
    width: 44px;
    height: 44px;
    line-height: 44px;
}

.__report-hours-table .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}


/* .__report-hours-table .ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background-color: var(--primary-color-hover) !important;
} */

.ant-table-tbody>tr.ant-table-row-selected:hover>td {
    background-color: var(--primary-color-hover) !important;
}

/* .ant-table-tbody>tr.ant-table-row-selected>td {
    background-color: rgba(var(--primary-color-rgb), 0.2) !important;
} */

.__min-height-100-percent {
    min-height: 100%;
}

.__centered-text {
    text-align: center !important;
}

.__ellipsis-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.__left-text {
    text-align: left !important;
}

.__min-width-50 {
    min-width: 50px;
}

.__min-width-80 {
    min-width: 80px;
}

.__min-width-100 {
    min-width: 100px;
}

.__min-width-120 {
    min-width: 120px;
}

.__min-width-130 {
    min-width: 130px;
}

.__min-width-140 {
    min-width: 140px;
}

.__min-width-150 {
    min-width: 150px;
}

.__min-width-170 {
    min-width: 170px;
}

.__min-width-200 {
    min-width: 200px;
}

.__min-width-220 {
    min-width: 220px;
}

.__min-width-250 {
    min-width: 250px;
}

.__min-width-300 {
    min-width: 300px;
}

.__width_0 {
    min-width: 0px;
    width: 0px;
    max-width: 0px;
}

.__width_25 {
    min-width: 25px;
    width: 25px;
    max-width: 25px;
}

.__width_30 {
    min-width: 30px;
    width: 30px;
    max-width: 30px;
}

.__width_35 {
    min-width: 35px;
    width: 35px;
    max-width: 35px;
}

.__width_50 {
    min-width: 50px;
    width: 50px;
    max-width: 50px;
}

.__width_60 {
    min-width: 60px;
    width: 60px;
    max-width: 60px;
}

.__width_70 {
    min-width: 70px;
    width: 70px;
    max-width: 70px;
}
.__width_75 {
    min-width: 75px;
    width: 75px;
    max-width: 75px;
}

.__width_80 {
    min-width: 80px;
    width: 80px;
    max-width: 80px;
}

.__width_90 {
    min-width: 90px;
    width: 90px;
    max-width: 90px;
}

.__width_100 {
    min-width: 100px;
    width: 100px;
    max-width: 100px;
}

.__width_110 {
    min-width: 110px;
    width: 110px;
    max-width: 110px;
}

.__width_120 {
    min-width: 120px;
    width: 120px;
    max-width: 120px;
}

.__width_130 {
    min-width: 130px;
    width: 130px;
    max-width: 130px;
}

.__width_140 {
    min-width: 140px;
    width: 140px;
    max-width: 140px;
}

.__width_150 {
    min-width: 150px;
    width: 150px;
    max-width: 150px;
}

.__width_160 {
    min-width: 160px;
    width: 160px;
    max-width: 160px;
}

.__width_165 {
    min-width: 165px;
    width: 165px;
    max-width: 165px;
}

.__width_170 {
    min-width: 170px;
    width: 170px;
    max-width: 170px;
}

.__width_175 {
    min-width: 175px;
    width: 175px;
    max-width: 175px;
}

.__width_180 {
    min-width: 180px;
    width: 180px;
    max-width: 180px;
}

.__width_190 {
    min-width: 190px;
    width: 190px;
    max-width: 190px;
}

.__width_200 {
    min-width: 200px;
    width: 200px;
    max-width: 200px;
}

.__width_220 {
    min-width: 220px;
    width: 220px;
    max-width: 220px;
}

.__width_250 {
    min-width: 250px;
    width: 250px;
    max-width: 250px;
}

.__width_260 {
    min-width: 260px;
    width: 260px;
    max-width: 260px;
}

.__width_270 {
    min-width: 270px;
    width: 270px;
    max-width: 270px;
}

.__width_280 {
    min-width: 280px;
    width: 280px;
    max-width: 280px;
}

.__width_300 {
    min-width: 300px;
    width: 300px;
    max-width: 300px;
}

.__width_350 {
    min-width: 350px;
    width: 350px;
    max-width: 350px;
}

.__width_400 {
    min-width: 400px;
    width: 400px;
    max-width: 400px;
}

.__report-hours-summary-user {
    min-width: 150px;
    width: 150px;
    max-width: 150px;
    text-align: left !important;
}

.__report-hours-summary-workrate {
    min-width: 110px;
    width: 110px;
    max-width: 110px;
    text-align: center !important;
}

.__report-hours-summary-hours-init {
    min-width: 85px;
    width: 85px;
    max-width: 85px;
    text-align: center !important;
}

.__report-hours-summary-to-report {
    min-width: 110px;
    width: 110px;
    max-width: 110px;
    text-align: center !important;
}

.__report-hours-summary-fixed-even,
.__report-hours-summary-fixed-odd {
    min-width: 110px;
    width: 110px;
    max-width: 110px;
    text-align: center !important;
}

th.__report-hours-summary-fixed-even,
th.__report-hours-summary-fixed-even-main {
    background-color: #f5f5f5 !important;
    text-align: center !important;

}

td.__report-hours-summary-fixed-even {
    background-color: #fafafa;

}

th.__report-hours-summary-fixed-odd,
th.__report-hours-summary-fixed-odd-main {

    background-color: #cfd8dc !important;
    text-align: center !important;
}

td.__report-hours-summary-fixed-odd {

    background-color: #eceff1;
}

.__report-hours-summary-fixed-right {
    text-align: center !important;
}

/* .__report-hours-table .ant-table-body {
    overflow: auto auto!important;
} */

.__report-hours-table .ant-table td.ant-table-cell-fix-left span.__report-hours-summary-user {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: block;
}

th.__report-hours-summary-fixed-left,
td.__report-hours-summary-fixed-left,
.__report-hours-summary-workrate {
    text-align: center !important;
}

th.__report-hours-summary-workrate,
td.__report-hours-summary-workrate {
    width: 110px !important;
    min-width: 110px !important;
    max-width: 110px !important;
}

.__report-hours-table .ant-table td.ant-table-cell-fix-left-last {
    min-width: 110px;
    max-width: 110px;
    text-align: right;
}

.__report-hours-table .ant-table th.ant-table-cell-fix-left-last {
    min-width: 110px;
    max-width: 110px;
    text-align: center;

}

.__report-hours-table .ant-table td.ant-table-cell-fix-left {
    min-width: 130px;
    max-width: 190px;
    text-align: left;
}

@media only screen and (max-width: 400px) {
    .__report-hours-table .ant-table td.ant-table-cell-fix-left {
        min-width: 130px;
        max-width: 150px;
        text-align: left;
    }
}

.__collapse-background-transparent {
    background-color: transparent !important;
    box-shadow: var(--box-shadow);

}

.__collapse-background-transparent .ant-collapse-content-active,
.__collapse-background-transparent .ant-collapse-content-inactive {
    background-color: transparent !important;
}


.__collapse-red .ant-collapse-header {
    color: red !important;
    /* background-color: #fff2f0;
    border: 1px solid #ffccc7;
    border-bottom: 0px!important; */
}

.__collapse-green .ant-collapse-header {
    color: green !important;
    /* background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    border-bottom: 0px!important; */
}

.__report-userslist-image,
.__report-userslist-download {
    text-align: center !important;
}

/* .__report-userslist-download {
    width: 140px!important;
    max-width: 140px!important;
}


.__report-userslist-image {
    width: 100px!important;
    max-width: 100px!important;
} */

.__report-groups-users-tags,
.__report-groups-users-tags-important,
.__tags-sunkhronos {
    border-radius: var(--border-radius);
    background: #f5f5f5;
    border: 1px solid #c6c6c6;
    color: #373737;
    font-size: 16px;
    line-height: 24px;
}

.__stafftype_tags {
    border-radius: var(--border-radius);
    background: #f5f5f5;
    border: 1px solid #c6c6c6;
    color: #373737;
    font-size: 12px;
    line-height: 18px;

}


.__report-groups-users-tags-important {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);

}


.__report-hours-table .__report-groups-users-tags,
.__report-hours-table .__report-groups-users-tags-important {
    margin-right: 0px;

}

.__tags-sunkhronos {
    margin-right: 0px !important;
}

.__tags-limit-size {
    max-width: 190px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.__balanceYearSummaryContent {
    display: flex;
    flex-direction: column;
}

.__balanceYearSummaryContent:not(:last-child) {
    border-bottom: 1px solid #bebebe;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.__user_report-child td {
    background-color: whitesmoke;
}

.__user_report-child td * {
    font-style: italic !important;
    font-size: 95% !important;
}

.__badge-spinner .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
}